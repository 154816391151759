const logout = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("account_name");
}

const isAuthenticated = () => localStorage.getItem("access_token") != null;

export default {
  logout,
  isAuthenticated
}